import { useRef } from "react";
import { Image, Button } from "antd-mobile";
import andBtn from '../../assets/images/andBtn.png';
import iosBtn from '../../assets/images/iosBtn.png';
import webBtn from '../../assets/images/webBtn.png';
import axios from "axios";
import { getDeviceType } from "../../utils/deviceUtils";
import styles from "./registeredSuccess.module.scss";

const node_env = process.env.NODE_ENV || "development";
console.log("node_env===>>>>", node_env);
interface DownloadConfig {
  ios_url: string;
  android_url: string;
  h5_url: string;
  download_url: string;
}
const RegisteredSuccess = () => {

  const downloadUrls = useRef<DownloadConfig>({
    ios_url: '',
    android_url: '',
    h5_url: '',
    download_url: '',
  });

  const randomTwoDigit = Math.floor(Math.random() * 90) + 10;
  // 使用 axios 发送 GET 请求
  axios.get(`/downloadConfig.json?${randomTwoDigit}`)
    .then((response) => {
      const dataUrl = response.data[node_env];
      dataUrl.map((url: any) =>
        getApiurl(url+`?${randomTwoDigit}`)
      )
    })
    .catch((err) => {
      // 请求失败，处理错误
      console.log(err);
    });

  // 根据url获取下载地址
  const getApiurl = (url: string) => {
    axios.get(url)
      .then((response) => {
        const { h5_package, ios_package, android_package } = response.data;
        const h5_num = getRandomNumber(h5_package);
        const ios_num = getRandomNumber(ios_package);
        const android_num = getRandomNumber(android_package);
        downloadUrls.current.ios_url = `${ios_package[ios_num]}`;
        downloadUrls.current.android_url = `${android_package[android_num]}`;
        downloadUrls.current.h5_url = `${h5_package[h5_num]}`;
      }
      )
  }

  // 获取随机数
  const getRandomNumber = (arr: []) => {
    const max = arr.length;
    return Math.floor(Math.random() * max);
  }


  let downUrl = useRef("");
  // const downloadRequest = async() => {
  //   // 发送请求 获取下载地址
  //   let data = await axios.get(downloadUrls.current.download_url || "");
  //   downUrl.current = data.data.link_jump;
  //   console.log("下载地址", downUrl.current);
  // };
  // 点击下载APP


  // const downloadApp = () => {
  //   // 根据getDeviceType判断下载地址
  //   let deviceType = getDeviceType();
  //   console.log("设备类型", deviceType);
  //   if (deviceType === "ios") {
  //     console.log('ios下载地址', downloadUrls.current.ios_url);
  //     window.location.href = downloadUrls.current.ios_url || "";
  //   } else if (deviceType === "Android") {
  //     console.log('android下载地址', downloadUrls.current.android_url);
  //     window.location.href = downloadUrls.current.android_url || "";
  //   } else {
  //     window.location.href = downloadUrls.current.android_url || "";
  //     // gotoH5();
  //   }
  // };

  // 点击IOS
  const gotoIos = () => {
    window.location.href = downloadUrls.current.ios_url || "";
  };


  // 点击安卓
  const gotoAndroid = () => {
    window.location.href = downloadUrls.current.android_url || "";
  };

  // 点击H5
  const gotoH5 = () => {
    window.location.href = downloadUrls.current.h5_url || "";
  };

  return (
    <div className="flex flex-col items-center justify-center overflow-hidden relative">
  <Image
    className="fixed top-0 left-0 right-0 bottom-0 -z-10"
    src={require("../../assets/images/login_bg.png")}
  />
  <Image
    src={require("../../assets/images/registere_successbg.png")}
    className="w-full h-auto max-w-full sm:max-w-[340px] md:max-w-[375px] lg:max-w-[445px]"
  />

<div className={`absolute top-[70vh] left-1/2 transform -translate-x-1/2 w-[90%] flex justify-center ${styles.ipadButtonWarp}`}>
  <Image
    src={require("../../assets/images/luodi_iconList.png")}
    className="w-full h-auto max-w-full sm:max-w-[340px] md:max-w-[375px] lg:max-w-[445px]"
  />
</div>



  {/* 按钮容器相对定位 */}
  <div 
   className={`absolute w-[90%] max-w-[480px] flex  sm:flex-row justify-center gap-4 ${styles.ipadButtonContainer}`}
    style={{ top: "80vh" }} 
  >
    <Button
      onClick={gotoIos}
      className={`flex-1 border-0 h-14 sm:h-16 md:h-18 rounded-2xl max-w-[180px] w-[96%] ${styles.ipadButton}`}
      style={{
        backgroundImage: `url(${iosBtn})`,
        backgroundSize: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'transparent',
      }}
    ></Button>
    <Button
      onClick={gotoAndroid}
      className={`flex-1 border-0 h-14 sm:h-16 md:h-18 rounded-2xl max-w-[180px] w-[96%] ${styles.ipadButton}`}
      style={{
        backgroundImage: `url(${andBtn})`,
        backgroundSize: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'transparent',
      }}
    ></Button>
  </div> 

  <div className={`absolute top-[90vh] flex justify-center w-full ${styles.ipadH5Button}`}>
    <Button
      onClick={gotoH5}
      className="border-0 h-12 rounded-2xl max-w-[200px] w-[96%]"
      style={{
        backgroundImage: `url(${webBtn})`,
        backgroundSize: '74%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'transparent',
      }}
    ></Button>
  </div>
</div>

  );
};

export default RegisteredSuccess;
