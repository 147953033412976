import React, { FC, useEffect, useState, useRef } from "react";
import { Image, Form, Input, Checkbox, Button, Toast } from "antd-mobile";
import { registerService, authCaptcha, getServer } from "../../services/user";
import { useRequest } from "ahooks";
import { Encrypt } from "../../utils/GzipSts";
import { useNavigate } from "react-router-dom";
import { EyeInvisibleOutline, EyeOutline } from "antd-mobile-icons";
import styles from "./register.module.scss";

import "./Login.css";
import axios from "axios";
import { getDeviceType } from "../../utils/deviceUtils";

const Register: FC = () => {
  const [Formregister] = Form.useForm();
  const navigator = useNavigate();

  const [checked, setChecked] = useState(false); //是否勾选了 阅读同意
  let agent = useRef<any>("");

  const [captchaData, setCaptchaData] = useState<any>({}); //图形验证码相关信息

  const [passWordvisible, setPassWordvisible] = useState(false);
  const [RepassWordvisible, setRePassWordvisible] = useState(false);
  // useEffect(() => {
  //   let pathname = window.location.href;
  //   agent.current = pathname.split("agent=")[1];
  //   getAllApiUrl()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const hasAgent = new URLSearchParams(window.location.search).has("agent");
  const hasFetched = useRef(false);
  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true; // 标记已执行
      let pathname = window.location.href;
      agent.current = pathname.split("agent=")[1];
      getAllApiUrl();
    }
  }, []);



  // 先校验
  const testhandleLogin = () => {
    let formData = Formregister.getFieldsValue();
    const { username, rePassword, password, captcha } = formData;
    if (!username) return Toast.show("请输入用户名");
    if (!password) return Toast.show("请输入密码");
    if (!rePassword) return Toast.show("请输入确认密码");
    // if (!nickname) return Toast.show("请输入昵称");
    if (!captcha) return Toast.show("请输入验证码");
    if (!checked) return Toast.show("请勾选同意用户协议");
    handleLogin();
  };


  // 获取所有api地址
  // const getAllApiUrl = () => {
  //   // 使用 axios 发送 GET 请求
  //   axios.get(`/downloadConfig.json?${randomTwoDigit}`)
  //     .then((response) => {
  //       const dataUrl = response.data[node_env];
  //       dataUrl.map((url: any) =>
  //         getApiurl(url + `?${randomTwoDigit}`)
  //       )
  //     })
  //     .catch((err) => {
  //       // 请求失败，处理错误
  //       console.log(err);
  //     });
  // }

  const getAllApiUrl = async () => {
    try {
      const response = await axios.get(`/downloadConfig.json?${randomTwoDigit}`);
      const dataUrl: string[] = response.data[node_env]; // 确保 dataUrl 是字符串数组

      for (const url of dataUrl) {
        const success = await getApiurl(url + `?${randomTwoDigit}`);
        if (success) {
          console.log("请求成功，停止后续请求");
          break; // ✅ 只要有一个请求成功，就停止
        }
      }
    } catch (err) {
      console.error("下载配置失败:", err);
    }
  };

  const getApiurl = async (url: string): Promise<boolean> => {
    try {
      const response = await axios.get(url);
      const { h5_package, ios_package, android_package, api } = response.data;

      if (!h5_package || !ios_package || !android_package || !api) {
        console.warn("数据不完整，跳过:", url);
        return false;
      }

      const h5_num = getRandomNumber(h5_package);
      const ios_num = getRandomNumber(ios_package);
      const android_num = getRandomNumber(android_package);

      localStorage.setItem("apiUrl", api);
      getCaptchaData();

      downloadUrls.current.ios_url = `${ios_package[ios_num]}`;
      downloadUrls.current.android_url = `${android_package[android_num]}`;
      downloadUrls.current.h5_url = `${h5_package[h5_num]}`;

      console.log("成功请求:", url);
      return true; // ✅ 请求成功，返回 true，停止后续请求
    } catch (error) {
      console.error("请求失败:", url, error);
      return false; // ❌ 失败，继续请求下一个
    }
  };

  // 点击注册方法
  const { run: handleLogin } = useRequest(
    async () => {
      let formData = Formregister.getFieldsValue();
      let postData = {
        ...formData,
        password: Encrypt(formData.password),
        rePassword: Encrypt(formData.rePassword),
        agree: 1,
        captchaId: captchaData.captchaId,
        inviteCode: agent.current || "", //邀请码
      };
      const apiUrl = localStorage.getItem('apiUrl');
      const apiUrls: any = apiUrl && apiUrl?.split(',');

      // 递归请求，直到成功
      const tryRequest: any = async (index = 0) => {
        if (index >= apiUrls.length) {
          throw new Error("所有 API 请求都失败");
        }

        const url = apiUrls[index];
        try {
          let data = await registerService(postData, url);
          if (data && data.code === 0) {
            return data;
          }
        } catch (error) {
          console.warn(`请求失败: ${url}`, error);
        }

        // 递归尝试下一个 URL
        return tryRequest(index + 1);
      };

      return tryRequest();
    },
    {
      manual: true,
      onSuccess(res: any) {
        const { code } = res;
        if (code === 0) {
          navigator("/RegisteredSuccess");
        } else {
          getCaptchaData();
        }
      },
    }
  );


  interface DownloadConfig {
    ios_url: string;
    android_url: string;
    h5_url: string;
    download_url: string;
  }

  const downloadUrls = useRef<DownloadConfig>({
    ios_url: '',
    android_url: '',
    h5_url: '',
    download_url: '',
  });
  const node_env = process.env.NODE_ENV || "development";
  const randomTwoDigit = Math.floor(Math.random() * 90) + 10;






  // 获取验证码

  let { loading, run: getCaptchaData } = useRequest(
    async () => {
      const apiUrl = localStorage.getItem('apiUrl');
      const apiUrls: any = apiUrl && apiUrl?.split(',');
      // 递归请求，直到成功
      const tryRequest: any = async (index = 0) => {
        if (index >= apiUrls.length) {
          throw new Error("所有 API 请求都失败");
        }

        const url = apiUrls[index];
        try {
          let data = await authCaptcha(url);
          if (data && data.code === 0) {
            return data;
          }
        } catch (error) {
          console.warn(`请求失败: ${url}`, error);
        }

        // 递归尝试下一个 URL
        return tryRequest(index + 1);
      };

      return tryRequest();
    },
    {
      manual: true,
      onSuccess(res) {
        const { code, data } = res;
        if (code === 0) {
          setCaptchaData(data);
        }
      },
      onError(err) {
        console.error("所有 API 请求失败:", err);
      }
    }
  );









  // 获取随机数
  const getRandomNumber = (arr: []) => {
    const max = arr.length;
    return Math.floor(Math.random() * max);
  }

  // 点击登录
  const gotoLogin = () => {
    console.log("LOGIN跳转地址+++", downloadUrls.current.h5_url);
    window.location.href = downloadUrls.current.h5_url || "";
  }

  // 联系客服
  const gotoLianxi = () => {
    gotoService()
  }

  let { run: gotoService } = useRequest(
    async () => {
      const apiUrl = localStorage.getItem('apiUrl');
      const apiUrls: any = apiUrl && apiUrl?.split(',');

      // 递归请求，直到成功
      const tryRequest: any = async (index = 0) => {
        if (index >= apiUrls.length) {
          throw new Error("所有 API 请求都失败");
        }

        const url = apiUrls[index];
        try {
          let data = await getServer(url);
          if (data && data.code === 0) {
            return data;
          }
        } catch (error) {
          console.warn(`请求失败: ${url}`, error);
        }

        // 递归尝试下一个 URL
        return tryRequest(index + 1);
      };

      return tryRequest();
    },
    {
      manual: true,
      onSuccess(res) {
        const { code, data } = res;
        if (code === 0) {
          window.location.href = data.url || "";
        }
      },
      onError(err) {
        console.error("所有 API 请求失败:", err);
      }
    }
  );

  return (
    <div className=" flex flex-col items-center justify-center overflow-hidden">
      <Image
        className=" fixed top-0 left-0 right-0 bottom-0 -z-10"
        src={require("../../assets/images/login_bg.png")}
      ></Image>

      <div className="mt-40">
        <Image src={require("../../assets/images/login_bgtitle.png")} style={{ padding: '0 25% 0 25%', width: '100%', height: '100%' }}></Image>
      </div>

      <div className=" text-[#666666]  flex flex-col  mt-16">
        <Form
          name="login_form"
          form={Formregister}
          onFinish={testhandleLogin}
          style={{
            maxWidth: 500,
            margin: "auto",
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: "transparent",
          }}
          initialValues={{ remember: true }}
          footer={
            <Button
              block
              type="submit"
              className=" bg-[#EAA31B] text-white border-0 rounded-xl w-5/6 m-auto h-12"
              size="large"
            >
              注册
            </Button>
          }
        >
          {/* 账号 */}
          <Form.Item
            name="username"
            // rules={[
            //   { required: true, message: "请输入6-20位（数字、英文）" },
            //   { min: 6, max: 20, message: "请输入6-20位（数字、英文）" },
            //   {
            //     pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,20}$/,
            //     message: "账户必须是6-20位的数字加大小写字母组合",
            //   },
            // ]}
            className="text-center  bg-transparent"
          >
            <div className="flex   text-[#666666] p-3  rounded-lg bg-[#0000003D]">
              <Image
                src={require("../../assets/images/username_icon.png")}
                width={24}
                height={24}
                className=" mr-5"
              ></Image>
              <Input
                className="rounded-lg "
                placeholder="请输入6-20位（数字、大小写英文）"
              />
            </div>
          </Form.Item>
          {/* 密码 */}
          <Form.Item
            name="password"
            // rules={[
            //   { required: true, message: "请输入6-20位（数字、英文）" },
            //   { min: 6, max: 20, message: "请输入6-20位（数字、英文）" },
            //   {
            //     pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,20}$/,
            //     message: "密码必须是6-20位的数字加大小写字母组合",
            //   },
            // ]}
            className="text-center bg-transparent"
          >
            <div className="flex bg-[#0000003D]  rounded-lg p-3  text-[#666666] ">
              <Image
                src={require("../../assets/images/lookpassicon.png")}
                width={24}
                height={24}
                className=" mr-5"
              ></Image>
              <Input
                className="rounded-lg "
                placeholder="请输入6-20位（数字、大小写英文）"
                type={passWordvisible ? "text" : "password"}
              />
              <div className={styles.eye}>
                {!passWordvisible ? (
                  <EyeInvisibleOutline
                    color="#fff"
                    onClick={() => setPassWordvisible(true)}
                  />
                ) : (
                  <EyeOutline
                    color="#fff"
                    onClick={() => setPassWordvisible(false)}
                  />
                )}
              </div>
            </div>
          </Form.Item>
          {/*确认密码*/}
          <Form.Item
            name="rePassword"
            // rules={[
            //   { required: true, message: "请确认密码" },
            //   ({ getFieldValue }) => ({
            //     validator(_, value) {
            //       if (!value || getFieldValue("password") === value) {
            //         return Promise.resolve();
            //       } else {
            //         return Promise.reject(new Error("两次密码不一致"));
            //       }
            //     },
            //   }),
            // ]}
            className="text-center bg-transparent "
          >
            <div className="flex  bg-[#0000003D]  rounded-lg  text-[#666666]   p-3">
              <Image
                src={require("../../assets/images/lookpassicon.png")}
                width={24}
                height={24}
                className=" mr-5"
              ></Image>
              <Input
                className="rounded-lg "
                placeholder="请再次输入密码"
                type={RepassWordvisible ? "text" : "password"}
              />
              <div className={styles.eye}>
                {!RepassWordvisible ? (
                  <EyeInvisibleOutline
                    color="#fff"
                    onClick={() => setRePassWordvisible(true)}
                  />
                ) : (
                  <EyeOutline
                    color="#fff"
                    onClick={() => setRePassWordvisible(false)}
                  />
                )}
              </div>
            </div>
          </Form.Item>
          {/* 昵称 */}
          {/* <Form.Item
            name="nickname"
            // rules={[
            //   { required: true, message: "请输入昵称" },
            //   { min: 4, max: 20, message: "昵称4-20位" },
            //   {
            //     pattern: /^[a-zA-Z0-9]{4,20}$/,
            //     message: "昵称4-20位，不能包含空格和特殊字符",
            //   },
            // ]}
            className="text-center bg-transparent"
          >
            <div className="flex bg-[#0000003D] rounded-lg  text-[#666666] p-3">
              <Image
                src={require("../../assets/images/nicknameicon.png")}
                width={24}
                height={24}
                className=" mr-5"
              ></Image>
              <Input className="rounded-lg " placeholder="请输入昵称" />
            </div>
          </Form.Item> */}
          {/* 验证码*/}
          <Form.Item
            name="captcha"
            // rules={[
            //   { required: true, message: "请输入验证码" },
            //   { min: 0, max: 8 },
            // ]}
            className="text-center bg-transparent  "
          >
            <div className="flex bg-[#0000003D] p-3  rounded-lg  text-[#666666] ">
              <Image
                src={require("../../assets/images/yanzhengmaicon.png")}
                width={24}
                height={24}
                className=" mr-5 min-w-6 min-h-6"
              ></Image>
              <Input className="rounded-lg  w-40" placeholder="请输入验证码" />
              <Image
                src={captchaData?.picPath}
                height={24}
                className=" mr-5 min-w-6 min-h-6  w-24 "
                onClick={() => {
                  getCaptchaData();
                }}
              ></Image>
            </div>
          </Form.Item>

          {/* 代理邀请码*/}
          {/* <Form.Item
            name="yanzhengma"
            className="text-center bg-[#0000003D] mt-2 rounded-lg  text-[#666666] "
          >
            <div className="flex ">
              <Image
                src={require("../../assets/images/yaoqingmalianjieicon.png")}
                width={24}
                height={24}
                className=" mr-5"
              ></Image>
              <Input
                className="rounded-lg "
                placeholder="请输入代理邀请码（非必填）"
              />
            </div>
          </Form.Item> */}
          <div className="  mt-7 ml-6">
            <Checkbox
              value="1"
              checked={checked}
              onChange={(e) => {
                console.log(e);
                setChecked(e);
              }}
            >
              <div>
                <span className="text-white">阅读并同意{" "}</span>
                <span className="text-[#FFC962] ">《NG扑克用户协议》</span>{" "}
              </div>
            </Checkbox>
          </div>
        </Form>
        <div>
    <div className="flex justify-center text-white items-center">
      <span className="text-[#EAA31B] cursor-pointer" onClick={gotoLianxi}>
        联系客服
      </span>
    </div>

    {!hasAgent && (
      <div className="flex justify-center text-white items-center mt-4">
        返回<span className="text-[#EAA31B] cursor-pointer" onClick={gotoLogin}>登录</span>
      </div>
    )}
  </div>




        <div className="mt-12 px-10 flex mb-10">
          {/* <Button
            onClick={downloadApp}
            className=" flex-1  bg-transparent text-[#EAA31B] border-1 border-[#EAA31B]  h-12 rounded-xl"
          >
            下载APP
          </Button>
          <Button
            onClick={gotoH5}
            className=" flex-1 ml-6 bg-transparent text-[#EAA31B] border-1 border-[#EAA31B]  h-12 rounded-xl"
          >
            快速游戏(H5)
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default Register;
