import axios from "axios";
import { Toast } from "antd-mobile";
// 获取token的方法  以后的项目可以直接从本地获取token


console.log('process.env.REACT_APP_API_BASE_URL',process.env.REACT_APP_API_BASE_URL)
const apiUrl  = localStorage.getItem('apiUrl');
// 请求延时 1 秒 以后的项目 去掉
const instance = axios.create({
  baseURL: `https://${apiUrl}/api/v1/`,
});

// request 拦截：每次请求 都带上token  （请求拦截器）
instance.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer token=`;

    return config;
  },
  (error) => Promise.reject(error)
);

// response 拦截：统一处理erron和msg  （响应拦截器）

instance.interceptors.response.use((res) => {
  const resData = (res.data || {}) as ResType;
  const { code, msg } = resData;
  // 以后的项目在这里 更改状态码  此项目 ==0表示成功
  if (code !== 0) {
    // 错误提示
    if (msg) {
      Toast.show(msg);
    }

    // throw new Error(msg);
  }

  return resData as any;
});

export default instance;

export type ResType = {
  code: number;
  data?: ResDataType;
  msg?: string;
};

export type ResDataType = {
  [key: string]: any;
};
