import React, { FC } from "react";
import { Image, Form, Input } from "antd-mobile";

import "./Login.css";

const Login: FC = () => {
  const [formlogin] = Form.useForm();

  const handleLogin = () => {
    console.log("登录");
  };

  return (
    <div className=" flex flex-col items-center justify-center">
      <Image
        className=" absolute top-0 left-0 right-0 bottom-0 -z-10"
        src={require("../../assets/images/login_bg.png")}
      ></Image>

      <div className="mt-40">
        <Image src={require("../../assets/images/login_bgtitle.png")} style={{padding: '0 25% 0 25%' }}></Image>
      </div>

      <div className=" text-[#666666]  flex items-center justify-center mt-16">
        <Form
          name="login_form"
          form={formlogin}
          onFinish={handleLogin}
          style={{
            maxWidth: 500,
            minWidth: 400,
            margin: "auto",
            paddingLeft: 20,
            paddingRight: 20,
            backgroundColor: "transparent",
          }}
          initialValues={{ remember: true }}
        >
          {/* 账号 */}
          <Form.Item
            name="username"
            rules={[
              { required: true, message: "请输入6-20位（数字、大小写英文）" },
              { min: 6, max: 20, message: "请输入6-20位（数字、大小写英文）" },
              {
                pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{6,20}$/,
                message: "账户必须是6-20位的数字加字母组合",
              },
            ]}
            className="text-center bg-[#0000004D]  rounded-lg border border-[#666666] text-[#666666] "
          >
            <div className="flex ">
              <Image
                src={require("../../assets/images/username_icon.png")}
                width={24}
                height={24}
                className=" mr-5"
              ></Image>
              <Input
                className="rounded-lg "
                placeholder="请输入6-20位（数字、大小写英文）"
              />
            </div>
          </Form.Item>
          {/* 密码 */}
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "请输入6-20位（数字、大小写英文）" },
              { min: 6, max: 20, message: "请输入6-20位（数字、大小写英文）" },
              {
                pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{6,20}$/,
                message: "密码必须是6-20位的数字加字母组合",
              },
            ]}
            className="text-center bg-[#0000004D]  rounded-lg border border-[#666666] text-[#666666]  mt-3"
          >
            <div className="flex ">
              <Image
                src={require("../../assets/images/lookpassicon.png")}
                width={24}
                height={24}
                className=" mr-5"
              ></Image>
              <Input
                className="rounded-lg "
                placeholder="请输入6-20位（数字、大小写英文）"
              />
            </div>
          </Form.Item>
          {/*确认密码*/}
          <Form.Item
            name="rePassword"
            rules={[
              { required: true, message: "请确认密码" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(new Error("两次密码不一致"));
                  }
                },
              }),
            ]}
            className="text-center bg-[#0000004D]  rounded-lg border border-[#666666] text-[#666666]  mt-3"
          >
            <div className="flex ">
              <Image
                src={require("../../assets/images/lookpassicon.png")}
                width={24}
                height={24}
                className=" mr-5"
              ></Image>
              <Input className="rounded-lg " placeholder="请再次输入密码" />
            </div>
          </Form.Item>
          {/* 昵称 */}
          <Form.Item
            name="nickname"
            rules={[{ required: true, message: "请输入昵称" }]}
            className="text-center bg-[#0000004D] mt-2 rounded-lg border border-[#666666] text-[#666666] "
          >
            <div className="flex ">
              <Image
                src={require("../../assets/images/username_icon.png")}
                width={24}
                height={24}
                className=" mr-5"
              ></Image>
              <Input className="rounded-lg " placeholder="请输入昵称" />
            </div>
          </Form.Item>
          {/* 验证码*/}
          <Form.Item
            name="captcha"
            rules={[{ required: true, message: "请输入验证码" }]}
            className="text-center bg-[#0000004D] mt-2 rounded-lg border border-[#666666] text-[#666666] "
          >
            <div className="flex ">
              <Image
                src={require("../../assets/images/username_icon.png")}
                width={24}
                height={24}
                className=" mr-5"
              ></Image>
              <Input className="rounded-lg " placeholder="请输入验证码" />
            </div>
          </Form.Item>

          {/* 代理邀请码*/}
          <Form.Item
            name="yanzhengma"
            className="text-center bg-[#0000004D] mt-2 rounded-lg border border-[#666666] text-[#666666] "
          >
            <div className="flex ">
              <Image
                src={require("../../assets/images/username_icon.png")}
                width={24}
                height={24}
                className=" mr-5"
              ></Image>
              <Input
                className="rounded-lg "
                placeholder="请输入代理邀请码（非必填）"
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
