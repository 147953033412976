import axios, { ResDataType } from "./ajax";

// 获取用户信息
export async function getUserInfoService(): Promise<ResDataType> {
  const url = "/api/user/info";
  const data = (await axios.get(url)) as ResDataType;

  return data;
}
// 注册
export async function registerService(body: any,api:any): Promise<ResDataType> {
  const url = `https://${api}/api/v1/auth/register`;
  // const url = "/auth/register";
  const data = (await axios.post(url, body)) as any;
  return data;
}

// 获取验证码
export async function authCaptcha(api:any): Promise<ResDataType> {
  console.log('api',api)
  const url = `https://${api}/api/v1/auth/captcha`;
  // const url = '/auth/captcha';
  const data = (await axios.get(url)) as ResDataType;
  return data;
}


// 获取联系客服
export async function getServer(api:any): Promise<ResDataType> {
  const url = `https://${api}/api/v1/customer_service/entrance/getone?channel=account&userid=null`;
  const data = (await axios.get(url)) as ResDataType;
  return data;
}
