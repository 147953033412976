import pako from "pako";
import CryptoJS from "crypto-js";

// 压缩数据为  Gzip 二进制
export function GzipFunc(str) {
  // 以及pako Gzip压缩传输方式
  let jsonstr = str;
  switch (typeof str) {
    case "object":
      jsonstr = JSON.stringify(str);
      break;
    case "number":
      jsonstr = toString(str);
      break;
    default:
      jsonstr = JSON.stringify(str);
      break;
  }
  let binaryString = pako.gzip(encodeURIComponent(jsonstr), { to: "string" });
  return binaryString;
  // ArryBufeer 传输方式
  // const arrayBuffer = new ArrayBuffer(encoded.length);
  // const view = new Uint8Array(arrayBuffer);
  // view.set(encoded)
  // console.log(encoded)
  // return encoded
}
// 解压  二进制Gzip数据
export async function UnGzip(e) {
  var blob = e.data;
  let strData;
  var reader = new FileReader();
  reader.readAsArrayBuffer(blob);
  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      if (e.target.readyState === FileReader.DONE) {
        let data = pako.inflate(reader.result);
        strData = String.fromCharCode.apply(null, new Uint16Array(data));
        resolve(JSON.parse(strData));
      } else {
        reject();
      }
    };
  });
}

export function Encrypt(
  str = "sdgasd",
  keyStr = "96ac58d7a2efba1f416d2489f9bde583"
) {
  var key = CryptoJS.enc.Utf8.parse(keyStr);
  var encrypted = CryptoJS.AES.encrypt(str, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

// 解密
export function Decrypt(str = "", keyStr = "96ac58d7a2efba1f416d2489f9bde583") {
  var key = CryptoJS.enc.Utf8.parse(keyStr);
  var decrypted = CryptoJS.AES.decrypt(str, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  const decryptStr = decrypted.toString(CryptoJS.enc.Utf8);
  return decryptStr.toString();
}
// 解压  二进制Blod数据
export async function UnBlod(e) {
  var blob = e.data;
  let strData;
  var reader = new FileReader();
  reader.readAsArrayBuffer(blob);
  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      if (e.target.readyState === FileReader.DONE) {
        let data = pako.inflate(reader.result);
        strData = String.fromCharCode.apply(null, new Uint16Array(data));
        resolve(JSON.parse(strData));
      } else {
        reject();
      }
    };
  });
}
