import React from "react";
import { createHashRouter } from "react-router-dom";
import Login from "../pages/Login/Login";
import RegisteredSuccess from "../pages/Login/RegisteredSuccess";
import Register from "../../src/pages/Login/Register";

const router = createHashRouter([
  {
    path: "/",
    element: <Register></Register>,
  },
  {
    path: "/login",
    element: <Login></Login>,
  },

  {
    path: "/RegisteredSuccess",
    element: <RegisteredSuccess></RegisteredSuccess>,
  },
]);

export default router;
